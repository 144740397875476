import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar, Footer, BtnWhatsApp } from "../components/shared";
import { BlogDetail, BlogView } from "../views/Blog";
import {
  CenterView,
  Diputacio,
  Valencia,
  Vendrell,
} from "../views/Center";
import ContactoView from "../views/Contacto/ContactoView";
import ErrorView from "../views/Error";
import HomeView from "../views/Home";
import { QuiropracticaView } from "../views/Quiropractica";
import { SolutionView } from "../views/Solution";
import { SubluxationView } from "../views/Subluxation";

const AppRouter = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomeView />} />
        <Route exact path="/the-chiropractic" element={<QuiropracticaView />} />
        <Route exact path="/subluxation" element={<SubluxationView />} />
        <Route exact path="/solutions" element={<SolutionView />} />
        <Route exact path="/centers" element={<CenterView />} />
        <Route exact path="/diputacio" element={<Diputacio />} />
        {/* <Route exact path="/masnou" element={<Masnou />} /> */}
        <Route exact path="/valencia" element={<Valencia />} />
        <Route exact path="/vendrell" element={<Vendrell />} />
        <Route exact path="/blog" element={<BlogView />} />
        <Route exact path="/blog/:id" element={<BlogDetail />} />
        <Route exact path="/contact" element={<ContactoView />} />
        <Route path="*" element={<ErrorView />} />
      </Routes>
      <Footer />
      <BtnWhatsApp />
    </Router>
  );
};

export default AppRouter;
