import { Container, Grid } from "@mui/material";
import { React, useContext, useEffect } from "react";
import { Banner } from "../../components/shared";
import MapContact from "../../components/shared/MapContact";
import { LanguageContext } from "../../context/LanguageContext";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import FormContact from "../Contacto/FormContact";
import { title, title_español, title_catalan, url_img } from "./const";

const CenterView = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(3);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={
          language === "es"
            ? title_español
            : language === "en"
            ? title
            : title_catalan
        }
        url_img={url_img}
        btn_form={false}
        center={true}
      />
      {/* SECTION BAJADA */}
      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          {language === "es" ? (
            <h1
              className="choose__title text-align-center"
              style={{ margin: "25px" }}
            >
              Visita Nuestros
              <br />
              Centros Quiroprácticos LinQ
            </h1>
          ) : language === "en" ? (
            <h1
              className="choose__title text-align-center"
              style={{ margin: "25px" }}
            >
              Visit Our
              <br />
              LinQ Chiropractic Centers
            </h1>
          ) : (
            <h1
              className="choose__title text-align-center"
              style={{ margin: "25px" }}
            >
              Visita Nuestros
              <br />
              Centres Quiroprácticos LinQ
            </h1>
          )}
        </Grid>
      </Container>

      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        direction="row"
        xs={12}
      >
        <MapContact />
      </Grid>

      {/* SECTION DIPUTACIO */}
      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <img
            src={`${process.env.REACT_APP_FRONTEND + "assets/image/pin.png"}`}
            alt="Pin"
            className="pin__img"
          />
          <h4 className="text__content__blue">DIPUTACIÓ</h4>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <h4 className="text__content__blue">
            {language === "es"
              ? "En LinQ Diputació atendemos cada día a más personas y les ayudamos a entender que la salud viene desde dentro.  Enseñamos conceptos básicos utilizando el sentido común para que puedan recuperar su salud de forma natural, para mantenerla así a largo plazo."
              : language === "en"
              ? "At LinQ Diputació we serve more and more people every day and help them understand that health comes from within. We teach basic concepts using common sense so that they can recover their health in a natural way, to maintain it in the long term."
              : "Al Centre LinQ Diputació atenem cada dia més persones i les ajudem a entendre que la salut ve des de dins. Ensenyem conceptes bàsics utilitzant el sentit comú perquè puguin recuperar la seva salut de manera natural, per mantenir-la així a llarg termini."}
          </h4>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          {language === "es" ? (
            <h4 className="text__content__blue">
              Los pacientes nos traen a sus amigos y familiares porque
              experimentan muchos beneficios a través de la Quiropráctica y
              quieren compartirlo con los demás.
              <br />
              Damos nuestro servicio con cariño y profesionalidad. Creamos un
              ambiente vital, agradable y educativo para que puedas gozar al
              máximo de los beneficios de la Quiropráctica.
            </h4>
          ) : language === "en" ? (
            <h4 className="text__content__blue">
              Patients bring their friends and family to us because they
              experience many benefits through Chiropractic and want to share it
              with others.
              <br />
              We provide our service with care and professionalism. We create a
              vital, pleasant and educational environment so that you can enjoy
              the benefits of Chiropractic to the fullest.
            </h4>
          ) : (
            <h4 className="text__content__blue">
              Els pacients ens porten els seus amics i familiars perquè
              experimenten molts beneficis a través de la Quiropràctica i volen
              compartir-ho amb els altres.
              <br />
              Donem el nostre servei amb afecte i professionalitat. Creem un
              ambient vital, agradable i educatiu perquè puguis gaudir al màxim
              dels beneficis de la Quiropràctica.
            </h4>
          )}
        </Grid>
        <Grid container xs={12}>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {language === "es"
                    ? "PEDIR CITA"
                    : language === "en"
                    ? "CONTACT"
                    : "DEMANAR CITA"}
                </span>
                <br />
                <br />
                93 451 53 01
                <br />
                695 197 802
                <br />
                diputacio@quirolinq.com
              </p>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {language === "es"
                    ? "HORARIOS"
                    : language === "en"
                    ? "SCHEDULE"
                    : "HORARIS"}
                </span>
                {language === "es" ? (
                  <>
                    <br />
                    <br />
                    Lunes 8:45 a 12:00 h. / 17:00 a 20:00 h.
                    <br />
                    Martes 16:00 a 19:00 h.
                    <br />
                    Miércoles 8:45 a 12:00 h. / 17:00 a 20:00 h.
                    <br />
                    Jueves 17:00 a 20:00 h.
                    <br />
                    Viernes 10:00 a 13:00 h.
                  </>
                ) : language === "en" ? (
                  <>
                    <br />
                    <br />
                    Monday 8:45 am to 12:00 pm / 5:00 pm to 8:00 pm
                    <br />
                    Tuesday 4:00 pm to 7:00 pm
                    <br />
                    Wednesday 8:45 am to 12:00 pm / 5:00 pm to 8:00 pm
                    <br />
                    Thursdays 5:00 pm to 8:00 pm
                    <br />
                    Fridays 10:00 am to 1:00 pm
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    Dilluns: 8:45 a 12:00 h. / 17:00 a 20:00 h.
                    <br />
                    Dimarts: 16:00 a 19:00 h.
                    <br />
                    Dimecres: 8:45 a 12:00 h. / 17:00 a 20:00 h.
                    <br />
                    Dijous: 17:00 a 20:00 h.
                    <br />
                    Divendres: 10:00 a 13:00 h.
                  </>
                )}
              </p>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {language === "es"
                    ? "DIRECCIÓN"
                    : language === "en"
                    ? "ADDRESS"
                    : "DIRECCIÓN"}
                </span>
                <br />
                <br />
                C/ Diputació, 168
                <br />
                08011 Barcelona
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <hr className="separator" />

      {/* SECTION VALENCIA */}
      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <img
            src={`${process.env.REACT_APP_FRONTEND + "assets/image/pin.png"}`}
            alt="Pin"
            className="pin__img"
          />
          <h4 className="text__content__blue">VALENCIA</h4>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          {language === "es" ? (
            <h4 className="text__content__blue">
              En LinQ Valencia nos apasiona tener algo tan importante entre
              manos: tu bienestar.
              <br />
              Nos dedicamos con cariño y profesionalidad a cada persona que
              entra dispuesta a aumentar su bienestar y calidad de vida.
            </h4>
          ) : language === "en" ? (
            <h4 className="text__content__blue">
              At LinQ Valencia we are passionate about having something so
              important in our hands: your well-being.
              <br />
              We dedicate ourselves with love and professionalism to each person
              who enters willing to increase their well-being and quality of
              life.
            </h4>
          ) : (
            <h4 className="text__content__blue">
              Al Centre LinQ Valencia ens apassiona tenir una cosa tan important
              entre mans: el teu benestar.
              <br />
              Ens dediquem amb afecte i professionalitat a cada persona que
              entra disposada a augmentar el seu benestar i qualitat de vida.
            </h4>
          )}
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          {language === "es" ? (
            <h4 className="text__content__blue">
              LinQ Valencia es un centro donde las personas se sienten como en
              casa donde la quiropráctica no solo les ha cambiado la vida sino
              que además forma parte de su cuidado de salud habitual.
              <br />
              Acuden todo tipo de clientes, desde niños a mayores y familias;
              con objetivos de todo tipo: mejorar el rendimiento deportivo,
              mejorar la postura, mantener la calidad de vida a lo largo del
              tiempo, etc.
            </h4>
          ) : language === "en" ? (
            <h4 className="text__content__blue">
              LinQ Valencia is a center where people feel at home and where
              chiropractic has not only changed their lives but also forms part
              of their regular health care.
              <br />
              All types of clients come, from children to seniors and families;
              with all kinds of objectives: to improve sports performance,
              improve posture, maintain quality of life over time, etc.
            </h4>
          ) : (
            <h4 className="text__content__blue">
              LinQ Valencia és un centre on les persones se senten com a casa i
              on la quiropràctica no solament els ha canviat la vida, sinó que a
              més forma part de la seva cura de salut habitual.
              <br />
              Venen tot tipus de clients, des de nens a gent gran i famílies
              senceres, amb objectius de tota mena: millorar el rendiment
              esportiu, millorar la postura, mantenir la qualitat de vida al
              llarg del temps, etc.
            </h4>
          )}
        </Grid>
        <Grid container xs={12}>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {language === "es"
                    ? "PEDIR CITA"
                    : language === "en"
                    ? "CONTACT"
                    : "DEMANAR CITA"}
                </span>
                <br />
                <br />
                932 15 89 07
                <br />
                695 19 78 02
                <br />
                valencia@quirolinq.com
              </p>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {language === "es"
                    ? "HORARIOS"
                    : language === "en"
                    ? "SCHEDULE"
                    : "HORARIS"}
                </span>
                {language === "es" ? (
                  <>
                    <br />
                    <br />
                    Lunes 9:30 a 13:00 h. / 15:00 a 19:30 h.
                    <br />
                    Miércoles 9:30 a 13:00 h. / 15:00 a 19:30 h.
                    <br />
                    Jueves 15:00 a 19:30 h.
                  </>
                ) : language === "en" ? (
                  <>
                    <br />
                    <br />
                    Monday 9:30 am to 1:00 pm / 3:00 pm to 7:30 pm.
                    <br />
                    Wednesday 9:30 am to 1:00 pm / 3:00 pm to 7:30 pm
                    <br />
                    Thursday 3:00 pm to 7:30 pm
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    Dilluns: 9:30 a 13:00 h. / 15:00 a 19:30 h.
                    <br />
                    Dimecres: 9:30 a 13:00 h. / 15:00 a 19:30 h.
                    <br />
                    Dijoues: 15:00 a 19:30 h.
                  </>
                )}
              </p>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {language === "es"
                    ? "DIRECCIÓN"
                    : language === "en"
                    ? "ADDRESS"
                    : "DIRECCIÓN"}
                </span>
                <br />
                <br />
                C/ Valencia 308,
                <br />
                08009 Barcelona
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <hr className="separator" />

      {/* SECTION VENDRELL */}
      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <img
            src={`${process.env.REACT_APP_FRONTEND + "assets/image/pin.png"}`}
            alt="Pin"
            className="pin__img"
          />
          <h4 className="text__content__blue">VENDRELL</h4>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          {language === "es" ? (
            <h4 className="text__content__blue">
              LinQ El Vendrell es un centro quiropráctico en Tarragona que abrió
              en el 2010 y desde entonces da servicio a todo tipo de personas.
              <br />
              Es un centro familiar ideal para todo tipo de casos y edades.
            </h4>
          ) : language === "en" ? (
            <h4 className="text__content__blue">
              LinQ El Vendrell is a chiropractic center in Tarragona that opened
              in 2010 and since then serves all types of people.
              <br />
              It is an ideal family center for all types of cases and ages.
            </h4>
          ) : (
            <h4 className="text__content__blue">
              LinQ El Vendrell és un centre que va obrir el 2010 i des de
              llavors dona servei a tota mena de persones.
              <br />
              És un centre familiar ideal per a tot tipus de casos i edats.
            </h4>
          )}
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          {language === "es" ? (
            <h4 className="text__content__blue">
              Gracias a su localización, muchas personas de alrededor de El
              Vendrell se puede beneficiar del cuidado quiropráctico.
            </h4>
          ) : language === "en" ? (
            <h4 className="text__content__blue">
              Thanks to its location, many people from around El Vendrell can
              benefit from chiropractic care.
            </h4>
          ) : (
            <h4 className="text__content__blue">
              Gràcies a la seva localització, moltes persones del voltant de El
              Vendrell es pot beneficiar del tractament quiropràctic.
            </h4>
          )}
        </Grid>
        <Grid container xs={12}>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {language === "es"
                    ? "PEDIR CITA"
                    : language === "en"
                    ? "CONTACT"
                    : "DEMANAR CITA"}
                </span>
                <br />
                <br />
                977 66 37 19
                <br />
                606 909 702
                <br />
                elvendrell@quirolinq.com
              </p>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {language === "es"
                    ? "HORARIOS"
                    : language === "en"
                    ? "SCHEDULE"
                    : "HORARIS"}
                </span>
                {language === "es" ? (
                  <>
                    <br />
                    <br />
                    Martes 9:00 a 13:00 h.
                    <br />
                    Jueves 9:00 a 13:00 h./ 16:00 a 20:00 h.
                    <br />
                    Sábado 10:00 a 13:00 h.
                  </>
                ) : language === "en" ? (
                  <>
                    <br />
                    <br />
                    Tuesday 9:00 am to 13:00 pm
                    <br />
                    Thursday 9:00 am to 13:00 pm / 16:00 pm to 20:00 pm
                    <br />
                    Saturday 10:00 am to 13:00 pm
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    Dimarts 9:00 a 13:00 h.
                    <br />
                    Dijous 9:00 a 13:00 h. / 16:00 a 20:00 h.
                    <br />
                    Dissabte 10:00 a 13:00 h.
                  </>
                )}
              </p>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {language === "es"
                    ? "DIRECCIÓN"
                    : language === "en"
                    ? "ADDRESS"
                    : "DIRECCIÓN"}
                </span>
                <br />
                <br />
                La Rambla, 10, Escalera 2 Entresuelo B
                <br />
                43700 El Vendrell (Tarragona)
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <hr className="separator" />

      {/* SECTION EL MASNOU */}
      {/* <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <img
            src={`${process.env.REACT_APP_FRONTEND + "assets/image/pin.png"}`}
            alt="Pin"
            className="pin__img"
          />
          <h4 className="text__content__blue">EL MASNOU</h4>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          {language === "es" ? (
            <h4 className="text__content__blue">
              El centro LinQ situado en El Masnou lleva muchos años funcionando
              en la zona. Es un lugar ideal para las personas que viven en la
              costa y alrededores que necesitan cuidado durante todo el año.
            </h4>
          ) : language === "en" ? (
            <h4 className="text__content__blue">
              The LinQ center located in Masnou has been operating in the area
              for many years. It is an ideal place for people living in and
              around the coast who need year-round care.
            </h4>
          ) : (
            <h4 className="text__content__blue">
              El centre LinQ situat al Masnou fa molts anys que està en
              funcionament a la zona. És un lloc ideal per a les persones que
              viuen a la costa i voltants que necessiten tractament durant tot
              l’any.
            </h4>
          )}
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          {language === "es" ? (
            <h4 className="text__content__blue">
              La atención es personalizada y a él acuden personas con toda clase
              de problemas, edades y condiciones.
            </h4>
          ) : language === "en" ? (
            <h4 className="text__content__blue">
              Personalized attention is provided to people with all kinds of
              problems, ages and conditions.
            </h4>
          ) : (
            <h4 className="text__content__blue">
              L’atenció és personalitzada i hi acudeixen persones amb tota
              classe de problemes, edats i condicions.
            </h4>
          )}
        </Grid>
        <Grid container xs={12}>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {language === "es"
                    ? "PEDIR CITA"
                    : language === "en"
                    ? "CONTACT"
                    : "DEMANAR CITA"}
                </span>
                <br />
                <br />
                93 555 10 67
                <br />
                646 471 754
                <br />
                elmasnou@quirolinq.com
              </p>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {language === "es"
                    ? "HORARIOS"
                    : language === "en"
                    ? "SCHEDULE"
                    : "HORARIS"}
                </span>
                {language === "es" ? (
                  <>
                    <br />
                    <br />
                    Martes 9:30 a 12:00 h. / 15:00 a 19:30 h.
                    <br />
                    Jueves 9:30 a 12:00 h. / 15:00 a 19:30 h.
                  </>
                ) : language === "en" ? (
                  <>
                    <br />
                    <br />
                    Tuesday 9:30 am to 12:00 pm / 3:00 pm to 7:30 pm
                    <br />
                    Thursday 9:30 am to 12:00 pm / 3:00 pm to 7:30 pm
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    Dimarts: 9:30 a 12:00 h. / 15:00 a 19:30 h.
                    <br />
                    Dijous: 9:30 a 12:00 h. / 15:00 a 19:30 h.
                  </>
                )}
              </p>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item"
            >
              <p className="description__item__photo text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {" "}
                  {language === "es"
                    ? "DIRECCIÓN"
                    : language === "en"
                    ? "ADDRESS"
                    : "DIRECCIÓN"}
                </span>
                <br />
                <br />
                C/ Navarra, 66
                <br />
                08320 El Masnou
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Container> */}

      <FormContact />
    </>
  );
};

export default CenterView;
